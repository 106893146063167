import React from 'react';
import { Container } from 'reactstrap';
import Link from 'gatsby-link';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <Container>
      <div className="row justify-content-center text-center" style={{ marginTop: 70, marginBottom: 70 }}>
        <div className="col-12 col-sm-6">
          <h1 className="display-2 m-0">404</h1>
          <h2 className="mb-3r">We sincerely apologize</h2>
          <p className="lead mb-4">
            The page you are looking for is no longer here. Maybe it was never here in the first place. In any case, we are sorry you were sent on this wild goose chase, and have already taken steps to have the person responsible fired.
          </p>
          <Link className="btn btn-primary" to="/">← Go Home </Link>
        </div>
      </div>
    </Container>
  </Layout>
);

export default NotFoundPage;
